import React from 'react';
import BlackTextVideoSection from '../components/BlackTextVideoSection/BlackTextVideoSection';
import Certifications from '../components/Certifications/Certifications';
import GeneralInquiriesForm from '../components/GeneralInquiries/GeneralInquiriesForm';
import HeroSection from '../components/HeroSection/HeroSection';
import ParallaxSection from '../components/ParallaxSection/ParallaxSection';
import TrueQualitySection from '../components/TrueQuality/TrueQualitySection';
import { indexContent } from '../content/indexPage';
import SEO from '../components/seo'

const indexPage = () => {
  return (
    <React.Fragment>
      <SEO title={"Home"} />
      <HeroSection {...indexContent.heroSection} />
      <BlackTextVideoSection {...indexContent.blackTextVideoSection} indexPage />
      <ParallaxSection {...indexContent.parallaxOne} />
      <Certifications />
      <ParallaxSection {...indexContent.parallaxTwo} />
      <TrueQualitySection />
      <GeneralInquiriesForm pageName="index" />
    </React.Fragment>
  );
};

export default indexPage;
