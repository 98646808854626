import classes from './YellowDivider.module.css';
import React from 'react';

const CertificationYellowDivider = () => {
  return (
    <div
      className={classes.certificationYellowDivider}
    />
  );
};

export default CertificationYellowDivider;
