const trueEqualityInfo = {
  header: "TRUE QUALITY",
  sectionInfo:
    "Fat and beef tenderness is not correlated. With traditional beef grading, " +
    "the higher the fat content/marbling, the higher the quality rating. Tenet™ " +
    "Beef is changing the industry by removing fat from the equation. Our markers " +
    "identify total muscle tenderness of each animal tested.",
}

export default trueEqualityInfo
