export const certificationsInfo = [
  {
    textIndex: 1,
    text:
      "Genetic markers enable Tenet™ to identify the TOP level of beef tenderness.",
  },
  {
    textIndex: 2,
    text:
      "Tenderness is an inherited trait and can be bred into a herd by testing, identifying and selecting Tenet™ animals.",
  },
]

export const tableDescription = [
  {
    rowHeader: "SUPERIOR TENDERNESS",
    rowText:
      "Tenet™ represents the highest level of naturally occuring beef tenderness. Tenet™ beef has superior tenderness.",
    filename: "SuperiorTenderness",
    alt: "Superior Certified Tenet",
  },
  // {
  //   rowHeader: "GUARANTEED TENDER",
  //   rowText:
  //     "Mid-Ten™-certified beef is the first guaranteed-tender beef certification. A Mid-Ten herd is significantly more tender than average U.S. beef.",
  //   filename: "MidTen",
  //   alt: "Guaranteed Certified Tenet",
  // },
]

export const bottomText = `` //Normal Section
