import React from "react"
import Image from "../Image/image"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import SmallYellowDivider from "../YellowDivider/SmallYellowDivider"
import SectionInfo from "./SectionInfo"
import classes from "./TrueQualitySection.module.css"
import FadeIn from "../Animations/FadeIn/FadeIn"
import FadeLeftSection from "../Animations/FadeLeft/FadeLeft"
import FadeDownSection from "../Animations/FadeDown/FadeDown"

const TrueQualitySection = () => {
  return (
    <React.Fragment style={{ color: "#000" }}>
      <FullWidthYellowDivider />
      <section className={classes.sectionContainer}>
        {SectionInfo && (
          <div>
            <div className={classes.sectionInfoContainer}>
              <div className={classes.flexEndHeader}>
                <FadeIn timeout={600}>
                  <h6 className={classes.sectionHeader}>
                    {SectionInfo.header}
                  </h6>
                </FadeIn>
                <FadeLeftSection timeout={200}>
                  <SmallYellowDivider color="yellow" alignLeft />
                </FadeLeftSection>
              </div>
              <div className={classes.textWrapper}>
                <FadeDownSection timeout={1000}>
                  <p className={classes.sectionInfo}>
                    {SectionInfo.sectionInfo}
                  </p>
                </FadeDownSection>
              </div>
            </div>
            <div className={classes.imageContainer}>
              <FadeDownSection timeout={1200}>
                <Image filename="TrueQuality_cow" alt="true equality cow" />
              </FadeDownSection>
            </div>
          </div>
        )}
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
}

export default TrueQualitySection
