import React from "react"
import classes from "./FadeDown.module.css"

const FadeDownSection = props => {
  const { children, timeout } = props
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setTimeout(() => {
          if (entry.isIntersecting) {
            setVisible(entry.isIntersecting);
          }
        }, timeout ? timeout : 0)
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={[
        classes.fadeDownSection,
        isVisible ? classes.isVisible : "",
      ].join(" ")}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export default FadeDownSection
