import * as React from 'react';
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import YellowDivider from '../YellowDivider/YellowDivider';
import classes from './GeneralInquiriesForm.module.css';

const GeneralInquiriesForm = ({ pageName }) => {
  return (
    <React.Fragment>
      <FullWidthYellowDivider />
      <section
        className={
          pageName === "index"
            ? classes.formSectionContainer
            : classes.contactPageSectionContainer
        }
      >
        {pageName === "index" && (
          <div className={classes.headerWrapper}>
            <h6 className={classes.sectionHeader}>GENERAL INQUIRIES</h6>
            <YellowDivider color="yellow" />
          </div>
        )}
        <div className={classes.sectionWrapper}>
          <form
            name={pageName === "index" ? "generalInquiries" : "contactPageForm"}
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            className={classes.formSection}
          >
            <input type="hidden" name="bot-field" />
            <input
              type="hidden"
              name="form-name"
              value={
                pageName === "index" ? "generalInquiries" : "contactPageForm"
              }
            />

            <input
              type="text"
              className={
                pageName === "index"
                  ? classes.textInput
                  : classes.contactPageTextInput
              }
              name="name"
              id="name"
              placeholder="NAME *"
              required
            />

            <input
              type="email"
              className={
                pageName === "index"
                  ? classes.textInput
                  : classes.contactPageTextInput
              }
              name="email"
              id="email"
              placeholder="EMAIL *"
              required
            />

            <input
              type="number"
              className={
                pageName === "index"
                  ? classes.textInput
                  : classes.contactPageTextInput
              }
              name="number"
              id="number"
              placeholder="PHONE NUMBER"
            />

            <textarea
              name="message"
              id="message"
              className={
                pageName === "index"
                  ? classes.textArea
                  : classes.contactPageTextArea
              }
              placeholder="MESSAGE"
            />

            <button
              type="submit"
              className={
                pageName === "index"
                  ? classes.submitButton
                  : classes.contactPageSubmitButton
              }
            >
              Submit
            </button>
          </form>
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
};

export default GeneralInquiriesForm;
