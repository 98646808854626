import classes from "../components/HeroSection/HeroSection.module.css"
import indexParallax from "../images/HomePage_Parallax.jpg"
import quoteParallax from "../images/HomePage_Parallax_Quote.jpg"
import indexHero from "../images/Home_Header-min.jpg"
import indexBlackText from "../images/black-text-section.jpg"

export const indexContent = {
  heroSection: {
    sectionId: "index-hero-section",
    background: indexHero,
    imageFile: "TenetBeef_LLC_Site-optimized.png",
    alt: "tenderness-certification",
    bottomImage: "HomePage_Beef.png",
    bottomImageClass: classes.homeImageWrapper,
  },
  blackTextVideoSection: {
    sectionId: "spices-n-herbs",
    background: indexBlackText,
    displayText: [
      {
        text: "Our certified tender beef is identified, not modified.",
        color: "rgba(255, 183, 0, 1)",
      },
      {
        text:
          "We identify genetic markers to determine, with 99% accuracy, the tenderness of beef.",
        // "We identify the TOP level of tenderness instead of 3 different levels of tenderness.",
      },
    ],
    video: "https://vimeo.com/364182792",
  },
  parallaxOne: {
    sectionId: "landing-parallax",
    background: indexParallax,
    alt: "parallax-one-image",
  },
  parallaxTwo: {
    sectionId: "quote-parallax",
    background: quoteParallax,
    alt: "quote-parallax-image",
    quoteText:
      '"Tenderness is the largest issue in American beef according to industry professionals, researchers, food science ' +
      'experts and most importantly the consumers."',
    quoteSignature: "Frank Hendrix, founder and CEO of Callipyge Genetics",
  },
}
