import * as React from "react"
import Image from "../Image/image"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import SmallYellowDivider from "../YellowDivider/SmallYellowDivider"
import classes from "./Certifications.module.css"
import {
  bottomText,
  certificationsInfo,
  tableDescription,
} from "./CertificationsInfo"
import CertificationYellowDivider from "../YellowDivider/CertificationYellowDivider"
import FadeLeftSection from "../Animations/FadeLeft/FadeLeft"
import FadeIn from "../Animations/FadeIn/FadeIn"
import FadeDownSection from "../Animations/FadeDown/FadeDown"

const Certifications = () => {
  return (
    <React.Fragment>
      <FullWidthYellowDivider />
      <section className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <FadeIn timeout={600}>
            <h6 className={classes.sectionHeader}>CERTIFICATIONS</h6>
          </FadeIn>
          <FadeLeftSection timeout={200}>
            <CertificationYellowDivider color="yellow" />
          </FadeLeftSection>
          <FadeDownSection timeout={1000}>
            <div className={classes.sectionInfoContainer}>
              {certificationsInfo.map(bullet => (
                <p key={bullet.textIndex} className={classes.certificationText}>
                  <span className={classes.bulletNumber}>
                    {bullet.textIndex}.
                  </span>{" "}
                  {bullet.text}
                </p>
              ))}
            </div>
          </FadeDownSection>
          <div className={classes.tableContainer}>
            {tableDescription.map((tableItem, index) => (
              <div key={index} className={classes.tableHeaderSection}>
                <div className={classes.imagesSection}>
                  <div className={classes.imageContainer}>
                    <Image alt={tableItem.alt} filename={tableItem.filename} />
                  </div>
                </div>
                <div className={classes.headerContainer}>
                  <FadeIn timeout={600}>
                    <h6 className={classes.tableHeader}>
                      {tableItem.rowHeader}
                    </h6>
                  </FadeIn>
                  <FadeLeftSection timeout={200}>
                    <SmallYellowDivider alignLeft />
                  </FadeLeftSection>
                </div>
                <FadeDownSection timeout={1000}>
                  <p className={classes.tableText}>{tableItem.rowText}</p>
                </FadeDownSection>
              </div>
            ))}
          </div>
          <p className={classes.bottomText}>{bottomText}</p>
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
}

export default Certifications
